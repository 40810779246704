.header {
  display: flex;
  justify-content: space-between;
}

.header h5 {
  font-size: 12px;
  display: inline;
  margin-left: 5px;
}

.details > div {
  margin: 8px 0;
}